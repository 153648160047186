import './App.css'
import { useEffect, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { Head } from './page/head'
import { Main } from './page/main'
import { useLocalStorage } from 'usehooks-ts'
import { ThemeProvider } from '@mui/material/styles'

import theme from './theme'

export const App = () => {
  const location = useLocation()
  const [user, setUser] = useState(null)
  useEffect(() => {}, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes location={location} key={location.pathname}>
          <Route path={'/'} element={<Head user={user} />}>
            <Route index element={<Main />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </>
  )
}
