import { Outlet } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Foot } from './foot'
export const Head = ({ user }) => {
  return (
    <>
      <header className='h-24 flex justify-start px-4 bg-black'></header>
      <Outlet />
      <Foot />
    </>
  )
}
