import { Outlet } from 'react-router-dom'
import { motion } from 'framer-motion'
import Link from '@mui/material/Link'

import Logo from './imgs/logo.svg'
import VFLogo from './imgs/vflogo.png'
import DPLogo from './imgs/data.png'

export const Foot = ({}) => {
  return (
    <>
      <footer className='w-full py-8 bg-slate-300'>
        <div className='container flex flex-col gap-2 justify-between items-end px-4 md:flex-row md:gap-4 md:px-6 lg:gap-2xl'>
          <div className='flex items-center gap-2 text-center md:order-2 md:justify-center md:gap-4 lg:order-1'>
            <Link href='https://techlab.org/'>
              <img className='p-2 mx-10' width={120} src={Logo} />
            </Link>
            <Link href='https://voteflare.org/'>
              <img className='p-2 mx-10' width={200} src={VFLogo} />
            </Link>
            <Link href='https://dataprivacylab.org/'>
              <img className='p-2 mx-10' width={180} src={DPLogo} />
            </Link>
          </div>
          <div className='flex items-center text-xs text-gray-500 md:order-1 md:justify-center lg:order-2 dark:text-gray-400'>
            <span>© 2024 PITL. All rights reserved.</span>
          </div>
        </div>
      </footer>
    </>
  )
}
