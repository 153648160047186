import { useEffect, useState } from 'react'
import _, { set } from 'lodash'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'

import ONE from './imgs/1.svg'
import TWO from './imgs/2.svg'
import THREE from './imgs/3.svg'
import FOUR from './imgs/4.svg'
import FIVE from './imgs/5.svg'

import ROLL from './imgs/c.svg'

import { motion } from 'framer-motion'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'

import axios from 'axios'

export const Main = ({}) => {
  const { register, handleSubmit, watch, setFocus } = useForm()
  const navigate = useNavigate()
  useEffect(() => {}, [])
  const SERVICES = [
    {
      img: TWO,
      h1: 'Voter Direct Communications',
      p: 'rollcallAI finds the best way to contact each voter - whether by text, voice, or email - and determines if the phone number or email is registered to the person, to deliver personalized messages for election officials to do better list maintenance or curing.',
    },
    {
      img: FIVE,
      h1: 'Real-time Analytics',
      p: 'rollcallAI can monitor voter record changes and provide statewide and county displays, alerting election officials by pinpointing where irregularities occur.',
    },
    {
      img: FOUR,
      h1: 'Data Quality Metrics',
      p: 'rollcallAI can complete a Voter File Quality Metric Test to strengthen election officials capabilities to cross-reference the highest quality data sources across 50 states and perform a predictive analysis to forecast mass voter challenges.',
    },
    {
      img: ONE,
      h1: 'Voter App Services',
      p: 'rollcallAI powers VoteFlare™ - which regularly checks official information from state and local governments and then emails, texts or phones you if your voter information changes. ',
    },
    {
      img: THREE,
      h1: 'Research and Analysis',
      p: 'rollcallAI leverages professional expertise across data, technology, and elections and offers personalized consultations to identify where support is needed the most, for both short and long term issues.',
    },
  ]
  return (
    <main className='w-screen flex flex-col min-h-[100dvh] justify-start items-start bg-black'>
      <section className='w-full py-6 md:py-12 lg:py-16 xl:py-24'>
        <motion.div className='container px-4 md:px-6'>
          <div className='grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]'>
            <div className='flex flex-col justify-center space-y-2 mx-14'>
              <div className='space-y-2'>
                <div className='afacad text-white text-8xl flex'>
                  {'rollcallAI'.split('').map((d, i) => (
                    <motion.div
                      className={i > 7 ? 'text-blue-400' : 'text-orange-500'}
                      initial={{ y: '100%', opacity: 0.01 }}
                      animate={{ y: '0px', opacity: 1 }}
                      transition={{
                        delay: i * 0.02,
                      }}
                    >
                      {d}
                    </motion.div>
                  ))}

                  <motion.svg viewBox={'0 0 180 124.692'} className='h-20 mx-4'>
                    {[
                      'M72.854,72.136c0,11.192-9.106,20.298-20.298,20.298s-20.298-9.106-20.298-20.298c0,0,0-50.465,0-50.465',
                      'M83.439,72.136c0,17.029-13.854,30.884-30.884,30.884-17.029,0-30.884-13.854-30.884-30.884,0,0,0-50.465,0-50.465',
                      'M94.025,72.136c0,22.866-18.603,41.469-41.469,41.469-22.867,0-41.47-18.603-41.47-41.469,0,0,0-50.465,0-50.465',
                      'M104.611,72.136c0,28.703-23.352,52.055-52.055,52.055-28.704,0-52.056-23.352-52.056-52.055,0,0,0-50.465,0-50.465',
                      'M104.611,52.555c0-11.192,9.106-20.298,20.298-20.298,11.193,0,20.298,9.106,20.298,20.298,0,0,0,50.465,0,50.465',
                      'M94.025,52.555c0-17.029,13.854-30.884,30.884-30.884,17.029,0,30.884,13.854,30.884,30.884,0,0,0,50.465,0,50.465',
                      'M83.439,52.555c0-22.866,18.603-41.469,41.469-41.469,22.867,0,41.47,18.603,41.47,41.469,0,0,0,50.465,0,50.465',
                      'M72.854,52.555c0-28.703,23.352-52.055,52.055-52.055,28.704,0,52.056,23.352,52.056,52.055,0,0,0,50.465,0,50.465',
                    ].map((d, i) => (
                      <motion.path
                        d={d}
                        strokeWidth={3}
                        stroke={'#515151'}
                        fill={'none'}
                        initial={{ pathLength: 0 }}
                        animate={{ pathLength: 1 }}
                        transition={{
                          delay: i * 0.05 + 0.2,
                        }}
                      />
                    ))}
                  </motion.svg>
                </div>
                <motion.p
                  className='max-w-[700px] text-gray-300 text-2xl'
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    delay: 1,
                    duration: 1.5,
                  }}
                >
                  a data and analytics infrastructure for mission critical services in elections.
                </motion.p>
              </div>
            </div>
          </div>
        </motion.div>
      </section>
      <section className='w-full py-2 md:py-4 lg:py-6 xl:py-8'>
        <motion.div
          className='flex justify-center'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 1,
            duration: 1.5,
          }}
        >
          <img src={ROLL} className='w-[110vw] rotate-180'></img>
        </motion.div>
        <div className='flex flex-row justify-start space-y-2 mx-14 pt-10'>
          <div className='p-6 rounded-lg border-slate-700 border-0'>
            <p className='max-w-[900px] text-gray-400 text-3xl'>
              Now more than ever, the accuracy of registered voter lists and dissemination of timely information for voters and election officials are paramount to preserving American democracy. <br />
              <br />
              By utilizing custom algorithms, the latest technologies, and pristine national data sources,
              rollcallAI serves as a backbone for mission critical services in
              elections. Our suite of turnaround projects focus on voter-direct
              communications, real-time analytics, data quality metrics,
              patented voter-app services, and research and analysis. Projects
              can be tailored to meet the unique needs of your state, county,
              or municipality.
            </p>
          </div>
        </div>
      </section>
      <section className='w-full py-6 md:py-12 lg:py-16 xl:py-24'>
        <div className='px-20 py-20'></div>
        <div className='flex flex-row justify-start px-10'>
          <div className='sticky top-[30vh] self-start p-10 border-t-2 border-t-slate-500'>
            <h1 className='text-slate-500 py-4 md:text-4xl/relaxed lg:text-6xl/relaxed'>
              Services
            </h1>
          </div>
          <div className='p-10 rounded-lg flex flex-col gap-4 mt-32'>
            {SERVICES.map((d, i) => (
              <div className='flex flex-row sticky my-10'>
                <div className='mr-8'>
                  <img src={d.img} width={120}></img>
                </div>
                <div className='flex flex-col gap-2'>
                  <h2 className='text-slate-200 text-2xl font-bold tracking-tight md:text-3xl/tight'>
                    {d.h1}
                  </h2>
                  <p className='mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400'>
                    {d.p}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className='w-full py-6 md:py-12 lg:py-16 xl:py-24 mt-20'>
        <div className='px-20 py-20'></div>
        <div className='flex flex-row justify-start px-10'>
          <div className='sticky top-[30vh] self-start p-10 border-t-2 border-t-slate-500 sm:text-sm'>
            <h1 className='text-slate-500 py-4 md:text-4xl/relaxed lg:text-6xl/relaxed'>
              Solutions
            </h1>
          </div>
          <div className='p-10 rounded-lg flex flex-col gap-4 mt-32'>
            <table className='text-xl font-medium'>
              <tr className='text-2xl text-slate-500'>
                <th className='w-[300px]'>Problem Facing Election Officials</th>
                <th className=''>rollcallAI's Solution</th>
              </tr>
              <tr>
                <td>
                  Voter outreach: inadequate funding to design materials and tools that enhance distribution capacity
                </td>
                <td>RollcallAI's Voter Direct Communications modernizes election administration by offering customizable alert systems that notify voters of timely, election-related issues by text, email, voice, or custom app.</td>
              </tr>
              <tr>
                <td>Lack of resources to improve list accuracy to mitigate high volume of list maintenance activities and address change</td>
                <td>RollcallAI has a comprehensive national data infrastructure that is centralized so election officials can avoid the costly piecemeal approach to list maintenance - immediately enhancing data access and integrity. Our research and analysis capabilities through Voter File Quality Metric Testing - predictive analysis and data source matching - can improve voter rolls and address emerging threats like forecasting mass voter challenges.</td>
              </tr>
              <tr>
                <td>Enhancing cyber security: infrastructure, training, and technical support</td>
                <td>RollcallAI maintains its own level 4 data security and analytics warehouse, without the need for an election office to share its data, reducing administrative and cost burdens.</td>
              </tr>
              <tr>
                <td>
                  Direct to voter monitoring
                </td>
                <td>
                  RollcallAI’s Voter-App Service VoteFlare allows voters to receive alerts when critical changes happen to their voter registration and can be used to inform voters directly of polling place information and ballot tracking.
                </td>
              </tr>
              <tr>
                <td>
                  Technological changes to voter registration databases
                </td>
                <td>
                  RollcallAI's Dashboard Alert System monitors voter record changes and provides statewide and county displays, alerting election officials by pinpointing where irregularities occur.
                </td>
              </tr>
              <tr>
                <td>Responding to onslaught of private voter challenges and mass voter challenges</td>
                <td>RollcallAI maintains pristine knowledge of voters to preemptively provide background data for public requests, such as voter challenges issued in mass challenges, allowing election officials early understanding for responses and action.</td>
              </tr>
              <tr>
                <td>Building trust with voters to thwart actors work to undermine confidence in democratic processes</td>
                <td>RollcallAI has a mandate to customize public interest technology to allow election officials to operate and communicate in the most transparent and efficient way. Our voter direct communications, by text and email, as an example, provide new and direct communication between voters and election officials to facilitate immediate voter attention to registrations or ballot tracking. (RollcallAI supplies the best phone numbers and addresses for voters and does not require the election office to provide them.)</td>
              </tr>
              <tr>
                <td>
                  Administering mail voting: cost, compliance, and ballot rejection; ballot curing and ballot tracking
                </td>
                <td>
                  RollcallAI’s Voter-App Services offer messaging capabilities (email, text, voice) through the design of in-house Notification System - catered to the unique needs of a county or state - to modernize curing, compliance, and list maintenance.
                </td>
              </tr>
              <tr>
                <td>
                  Polling place security 
                </td>
                <td>
                  RollcallAI offers election apps for Apple and Android devices for real-time tracking of polling place wait times and direct alerts. (RollcallAI also provides support for rides to the polls by Uber and Lyft.)
                </td>
              </tr>
              <tr>
                <td>Combatting mis-and-disinformation</td>
                <td>RollcallAI has a partnership with SameSource, which tracks the origins and flows of mis- and disinformation in visual and auditory media, and with the Political Messaging Archive, which track voter direct communications. Instances most relevant to a specific election office are provided to election officials through RollcallAI along with the known provenance of the media so election officials have the latest knowledge to combat mis- and disinformation.</td>
              </tr>
              <tr>
                <td>
                  Turnover of election officials and volunteers from previous
                  elections
                </td>
                <td>
                  RollcallAI provides alerts to election officials when
                  something needs their attention and provides easy to use
                  turnkey solutions to problems. There is little or no learning
                  curve for new election officials.
                </td>
              </tr>
              <tr>
                <td>
                  Lack of funding and staffing for basic operations of administering elections and enhancing election infrastructure; supporting election administration in rural and remote areas
                </td>
                <td>
                  RollcallAI provides cost-effective and professional data, technology and elections tools and expertise for meaningful insights and enhancements to election infrastructures.
                </td>
              </tr>
              <tr>
                <td>
                  Data sources for list maintenance; ineffectiveness of National Change of Address Data; cost of ERIC alternatives and using outdated data; election mail handling and processing
                </td>
                <td>
                  RollcallAI has the latest and most pristine integrated data sources and most robust and accurate algorithms for election use.
                </td>
              </tr>
              <tr>
                <td>
                  The latest AI innovations
                </td>
                <td>
                  In addition to data and algorithms, RollcallAI’s professional team has the latest chatbot and LLM technologies and the most recent results about their trustworthiness in election applications.
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
      <section className='w-full py-2 md:py-4 lg:py-6 xl:py-8'>
        <div className='flex flex-row justify-start space-y-2 mx-14'>
          <div className='p-6 rounded-lg border-slate-700 border-0'>
            <p className='max-w-[900px] text-gray-400 text-3xl'>
              rollcallAI uses traditional and non-traditional data sources and custom inference algorithms that have been vetted through decades of research results from the Data Privacy Lab. 
            </p>
          </div>
        </div>
      </section>
    </main>
  )
}
